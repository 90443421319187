import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'view-ui-plus/dist/styles/viewuiplus.css'
import { Message } from 'element-ui';
Vue.use(ElementUI, { size: 'small', zIndex: 3000 });

Vue.config.productionTip = false
Vue.prototype.$message = Message;

new Vue({
    router,
    store,
    render: function (h) { return h(App) }
}).$mount('#app')
