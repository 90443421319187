import Vue from 'vue'
import VueRouter from 'vue-router'
import { getStore } from "@/utils/storage";


Vue.use(VueRouter)

const routes = [
    {
        path: '/login',
        name: 'login',
        component: () => import('@/views/login.vue'),
    },
    {
        path: '/register',
        name: 'register',
        component: () => import('@/views/register.vue'),
    },
    {
        path: '/sell',
        name: 'sell',
        component: () => import('@/views/sell.vue'),
    },
    {
        path: '/user',
        name: 'user',
        component: () => import('@/views/user.vue'),
    },
    {
        path: '/',
        name: 'layout',
        component: () => import('@/layout/index.vue'),
        children: [
            {
                path: '/',
                name: 'home',
                component: () => import('@/views/home.vue')
            },
            {
                path: '/content/:id',
                name: 'content',
                component: () => import('@/views/content.vue'),
                children: [{
                    path: '/firstContent/:id',
                    name: 'firstContent',
                    component: () => import('@/views/firstContent.vue')
                },
                {
                    path: '/secondContent/:id',
                    name: 'secondContent',
                    component: () => import('@/views/secondContent.vue')
                }]
            },]
    }
]

// 防止连续点击多次路由报错
let routerPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return routerPush.call(this, location).catch(err => err)
}



const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})


router.beforeEach((to, from, next) => {
    const token = getStore('token');
    if (token) {
        if (to.path === '/login') {
            next({ path: '/' });
        } else {
            next();
        }
    }
    next();
});


export default router
